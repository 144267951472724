.testimonials-txt1 {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
}

.profileImg {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border-width: 2px;
  border-color: red;
  // border: 2px solid #fff;
}
