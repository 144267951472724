.skills-company::before {
  content: "\f0da";
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  top: 12px;
  left: -16px;
}

.skills-company {
  position: relative;
  color: #fff;
  font-size: 24px;
}

.skills-location {
  color:rgba($color: #fff, $alpha: 0.4);
  font-size: 15px;
}

.skills-role {
  color:rgba($color: #fff, $alpha: 0.8);
  font-size: 17px;
}

.skills-tenure {
  color: #fff;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  font-weight: 500;
  margin-top: 1px;
}

.skills-line {
  color: rgba($color: #fff, $alpha: 0.3);
  border: 0 none;
  background-color: rgba($color: #fff, $alpha: 0.3);
  height: 0.1px;
}
