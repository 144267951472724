.txt1 {
  color: #fff;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 18px;
  padding: 0;
  margin: 0;
}

.txt2 {
  margin: 0;
  padding: 0;
  font-family: 'Inika';
  font-weight: 400;
  color: #fff;
  font-size: 36px;
}

.icons-container {
  width: 250px;
  height: 50px;
}

.icon {
  width: 24px;
  height: 24px;
  color: rgba($color: (#fff), $alpha: 0.12);
  cursor: pointer;
}

.icon:hover {
  color: #fff;
  transition: all 800ms ease;
  transform: scale(1.05);
}

.input {
  background-color: rgba($color: (#fff), $alpha: 0.12);
  border: none;
  border-radius: 2px;
  height: 50px;
  padding: 0 20px;
  color: #fff;
  font-size: 16px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #37313f inset !important;
    box-shadow: 0 0 0 30px #37313f inset !important;
    -webkit-text-fill-color: #fff !important;
}

.input:focus {
  outline: none;
  border-bottom: 2px solid #fff;
}

.text-area {
  background-color: rgba($color: (#fff), $alpha: 0.12);
  border: none;
  border-radius: 2px;
  flex-basis: 48%;
  height: 200px;
  padding: 20px 20px;
  color: #fff;
  resize: none;
  font-size: 14px;
}

.text-area:focus {
  outline: none;
  border-bottom: 2px solid #fff;
}


