button {
  border: 1px solid #fff;
  color: #fff;
  -webkit-transition: ease-out 0.3s;
  -moz-transition: ease-out 0.3s;
  transition: 0.3s ease-out all;
}

button:hover {
  box-shadow: inset 400px 0 0 0 #fff;
  color: #1B1425;
}

.md {
  padding: 8px 20px;
}

.md-round {
  padding: 8px 20px;
  border-radius: 5px;
}