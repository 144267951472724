#loadingScreen {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.logo-wrapper {
  width: max-content;
  max-width: 100px;
}

svg {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  fill: none;
  user-select: none;
}
