.ReactModal__Overlay {
  opacity: 0;
  transition: all 400ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000, $alpha: 0.8)
}

.content {
  position: absolute;
  top: 5%;
  left: 20%;
  right: 20%;
  bottom: 10%;
  background: linear-gradient(116.17deg, #1B1425 11.08%, #311824 49.37%, #6B2120 100.87%);
  border: 1px solid #ccc;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
}

.modal-content-container {
  scrollbar-color: #6969dd #e0e0e0;
}

.details {
  padding: 10px;
}

.technologies-text {
  color: #fff;
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .content {
    top: 25%;
    left: 5%;
    right: 5%;
    bottom: 25%;
  }
}

@media screen and (max-width: 899px) {
  .content {
    top: 20%;
    left: 10%;
    right: 10%;
    bottom: 15%;
  }
}
  
