.outline-logo {
  width: 42px;
  height: 42px;
}

.nav-links {
  color: rgba($color: #fff, $alpha: 0.6);
  transition: opacity 0.4s ease-in-out;
  cursor: pointer;
}

.nav-icon {
  background: none;
  cursor: pointer;
  border: none;
  outline: none;
}

.icon-line {
  display: block;
  border-radius: 50px;
  width: 35px;
  height: 3px;
  margin: 8px;
  background-color: #fff;
  transition: width 0.4s ease-in-out;
}

.sidenav {
  height: 100%;
  width: 0;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  overflow-x: hidden;
  transition: 0.4s;
  padding-top: 60px;
  text-align:center;
}

