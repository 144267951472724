.main-text {
  font-family: 'Inika';
  font-weight: 400;
  color: #fff;
  font-size: 28px;
}

.img-container{
  position: relative;
  overflow: hidden; 
}

.img {
  display: block;
  object-position: center;
  transition: .5s ease;
  backface-visibility: hidden;
}

.project-name {
  position: absolute;
  inset: 0;
  opacity: 0;
  font-size: 24px;
  font-family: 'Inter';
  text-decoration: underline;
  color: #fff;
  background-color:rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-container > .img,
.img-container > .project-name {
  transition: 400ms ease-in-out;
}

.img-container:hover > .img {
  transform: scale(1.2);
  filter: blur(2px);
}

.img-container:hover > .project-name {
  opacity: 1;
  cursor: pointer;
}

.fit-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cover-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
